import { Button, Container, Grid, Typography } from "@mui/material"
import { GeneralPublicLayout } from "components/home/general.layout"
import { useNavigate } from "react-router-dom"
import { site_name } from "util.config"

export const WeedDeliveryLocationsList = ({title}:any) => {

    let delivery_locations = ["Anaheim", "Santa Ana", "Costa Mesa", "Huntington Beach", "Laguna Beach", "Aliso Viejo", "Mission Viejo", "Dana Point", "Ladera Ranch", "Newport Beach", "Laguna Niguel", "Newport Coast", "Crystal Cove", "Westminster", "Yorba Linda", "Fountain Valley", "Seal Beach", "Irvine", "Rancho SM", "San Clemente", "SJC", "Tustin", "Laguna Woods", "Lake Forest", "Laguna Hills", "Pelican Hill", "Orange", "Fullerton", "Brea", "Placentia", "La Habra", "Garden Grove", "Coto De Caza", "Villa Park"]
    
    return (
    <Grid item xs={4} sm={4} md={6} style={{marginBottom:20}}>
        <Typography variant="h4" style={{marginBottom:20}}>
        {title}
        </Typography>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {delivery_locations.map((_, index) => (
            <Grid item xs={2} sm={2} md={4} key={index}>
                <Typography>
                    {_}
                </Typography>
            </Grid>
        ))}
        </Grid>
    </Grid>
    )
}

export const HomePage = () => {
    let navigate = useNavigate()
    let reasons = ["We DO NOT carry fake/cheap imitation products. We sell authentic cannabis products only.", {site_name}+" Orange County was awarded 1st place in the 2018 Dabathon Cup for its Nuggz marijuana edibles.", "We deliver over 50 award-winning marijuana strains and products from lab-tested, pesticide-free cannabis farmers.", "We partner with veteran California cannabis farmers to ensure all marijuana plants are grown responsibly, free of pesticides, and with minimum carbon footprint.", "We deliver the highest potency indoor and outdoor weed flower available in Orange County.", "We carry only top-shelf marijuana products.", "Free delivery for orders over $50.", "Scan the QR code on your receipt for extra goodies!"]
    
    let cannabis_logo = [
        "westcoast-cure-logo.png",
        "dime-cannabis-logo.png",
        "kanha-cannabis-gummies.jpeg",
        "papa-barkley-4.png",
        "wyld-edibles.jpeg",
        "Kiva-Confections-Logo.png",
        "Sensi-Products-Bud-Man-OC.png",
        "jeeter-prerolls-joints.jpeg",
        "stiiizy-logo.png",
        "CAM.png"
    ]

    let price_match_imgs = [
        "4G8TH.png",
        "blunts-prerolls.png",
        "VIPgift.png",
        "newcustomergift.png"
    ]

    return(
       <GeneralPublicLayout>
        <Container style={{textAlign:'center', paddingBottom:10, marginBottom:20, borderBlockStyle:'solid'}}>
            <Typography variant="h3">
                Orange County
            </Typography>
            <Typography variant="h4">
                Marijuana Delivery
            </Typography>
        </Container>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={6}>
                <Typography variant="h4">
                Weed Delivery for Orange County, California
                </Typography>
                <Typography>
                Your locally owned dispensary, serving Orange County weed lovers since 2014. 
                We deliver premium marijuana products to your home, office, or hotel in Orange County, California. 
                Shop our online marijuana menu 24/7. Choose from award-winning weed strains, including sativa, indica, and hybrid flower as well as a wide cannabis edibles and vape carts. 
                Deliveries 10am to 10pm every day of the year to your Orange County address, including locations in Anaheim, Mission Viejo, Santa Ana, Newport Beach, Coto de Caza, Huntington Beach, and Laguna Beach.
                </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={3} style={{textAlign:'center'}}>
                <Button onClick={() => navigate("/public/products")} variant="contained">View Menu</Button>
                <img style={{padding:5}} width={"100%"} src="https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/canna_jar.jpg" />
            </Grid>
            <Grid item xs={2} sm={4} md={3} style={{textAlign:'center'}}>
                <Button onClick={() => navigate("/public/new-customers")} variant="contained">New Customers</Button>
                <img style={{padding:5}} width={"100%"} src="https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/support_%20budtender.png" />
            </Grid>
        </Grid>
        <Typography variant="h4" style={{textAlign:'center', padding:20}}>
        Your Locally Owned Dispensary Since 2014
        </Typography>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{textAlign:'center', marginBottom:20}}>
            <Grid item xs={2} sm={4} md={4}>
                <Button onClick={() => navigate("/public/categories/show/Hybrid Top Shelf")} variant="contained">Weeed</Button>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
                <Button onClick={() => navigate("/public/categories/show/Edibles")} variant="contained">Edibles</Button>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
                <Button onClick={() => navigate("/public/categories/show/Vape Options")} variant="contained">Vape Carts</Button>
            </Grid>
        </Grid>
        <Typography>
        We deliver premium marijuana to your home, business, or hotel at any location in Orange County. Our legendary drivers deliver top-shelf marijuana flower cultivated by veteran cannabis farmers from across the Golden State. Our marijuana menu includes a wide selection of indica, sativa, and hybrid flower as well as edibles, vape carts, and supplies.
        </Typography>
        <br />
        <Typography> 
        All of our cannabis products are lab tested to ensure quality and purity – whether indoor/outdoor cannabis flower, edibles, concentrates, or vape carts. {site_name} Orange County products are organic, free of pesticides, gluten-free, and vegan. Shop our online marijuana dispensary now.
        </Typography>
        <Typography variant="h4" style={{textAlign:'center', padding:20}}>
        We will price-match any competitor.
        </Typography>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{marginBottom:40}}>
        {price_match_imgs.map((_, index) => (
            <Grid item xs={2} sm={2} md={3} key={index}>
                <img width={"100%"} src={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+_} />
            </Grid>
        ))}
        </Grid>

        <img width={"100%"} src="https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/canna_pic1.png" />
        <img width={"100%"} src="https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/canna_pic2.png" />

        <Typography variant="h4" style={{textAlign:'center', padding:20}}>
        Weed Delivery 10am to 10pm Every Day.
        </Typography>
        <Typography variant="h4" style={{textAlign:'center'}}>
        Most Trusted Marijuana Dispensary in Orange County.
        </Typography>
        <ul>
            {reasons.map((x, idx) =>
            <li key={idx}>
                {x}
            </li>
            )}
        </ul>
        <Container style={{textAlign:'center', marginBottom:40}}>
            <Button onClick={() => navigate("/public/products")} variant="contained" style={{margin:20}}>Shop Cannabis Now</Button>
            
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                {cannabis_logo.map((img, indx) => 
                <Grid item xs={1} sm={2} md={2} key={indx}>
                    <img width={"100%"} src={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+img} />
                </Grid>
                )}
            </Grid>
        </Container>
        

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={6} >
                <Typography variant="h4">
                Orange County Cannabis Delivery
                </Typography>
                <Typography>
                We are your locally owned marijuana delivery dispensary serving Orange County, California since 2014: Fast and discreet weed delivery to your home, workplace, Airbnb, or hotel. Our team delivers marijuana to your Orange County address every day of the year 10am to 10pm. Our selection of cannabis products includes award-winning marijuana flowers, edibles, and vape carts. Be sure to ask if you have questions about any of our cannabis products. Our budtenders are delighted to help!
                </Typography>
                <Button variant="contained">
                    Shop Weed Now
                </Button>
            </Grid>
            <WeedDeliveryLocationsList title={'Weed Delivery for Orange County'} />
        </Grid>
       </GeneralPublicLayout> 
    )
}