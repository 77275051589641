import { Button, Container } from "@mui/material"

export const ProductFilter = (props:any) => {
    const { price, setPrice } = props
    return (
        <Container style={{padding:20}}>
            <Button style={{marginRight:10}} onClick={() => setPrice(1)} variant={price === 1 ? "contained" : "outlined"}>
                Price : Low to High
            </Button>
            <Button onClick={() => setPrice(-1)} variant={price === -1 ? "contained" : "outlined"}>
                Price : High to Low
            </Button>
        </Container>
    )
}