import { Grass } from "@mui/icons-material"
import { useState, useEffect } from 'react';

export const site_name = "Fast OC Bud"

export const site_phone_number = "(909) 929-2891"

export const site_email = "info@fastocbud.com"

export const site_domain = "fastocbud.com"

export const SiteIcon = () => <Grass color="success" style={{fontSize:30}} />

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}