import { Button, Container, Typography } from "@mui/material"
import { GeneralPublicLayout } from "components/home/general.layout"

export const FAQpage = () => {
    let faq_facts = [
        {
            question:"Do you accept credit/debit cards for marijuana purchases?", 
            answer:"We do not accept credit or debit cards for at the moment. We are strictly cash upon delivery due to federal banking laws regarding marijuana."
        },
        {
            question:"Do you deliver weed to hotels in Orange County?", 
            answer:"Hell yeah! If you order weed online, be sure to give us the hotel name and where you would like to meet your weed delivery driver."
        },
        {
            question:"Why don’t your weed delivery drivers carry change?", 
            answer:"For safety of our weed delivery drivers, they do not carry change. However, if you need change let us know in the order notes, and we will try to accommodate you."
        },
        {
            question:"Do you have a marijuana dispensary store?", 
            answer:"We do not currently have a weed dispensary store that is open to the public. We are a mobile marijuana delivery dispensary only. You can order weed directly from the website, or give us a call to place a marijuana order over the phone."
        },
        {
            question:"I just sent in my new marijuana customer details, when can I start ordering weed?", 
            answer:"Once you submit your new marijuana customer details and a photo of a valid ID verifying 21+ age, you can start ordering weed immediately."
        },
        {
            question:"Do you ship weed products to other cities or states?", 
            answer:"Due to federal regulations, we are unable to ship marijuana products. If you find yourself in Orange County, California, please let us know and we'll be delighted to deliver weed to your hotel, workplace, or residence!"
        },
        {
            question:"When will I receive my weed delivery order?", 
            answer:"We deliver weed to any location in Orange County, usually within an hour."
        },
    ]
    return(
       <GeneralPublicLayout>
            <Typography variant="h4">
            Frequently Asked Questions about Marijuana Weed Delivery in Orange County
            </Typography>
            <Container>
                <Button style={{marginTop:20, marginBottom:20}} variant="contained">Shop Marijuana Now</Button>

                {faq_facts.map((fact, idx) =>
                <div key={idx} style={{margin:20}}>
                    <Typography variant="h5">
                        {fact.question}
                    </Typography>
                    <Typography>
                        {fact.answer}
                    </Typography>
                </div>
                )}
                            
                <Button style={{marginTop:20, marginBottom:20}} variant="contained">Shop Marijuana Now</Button>

            </Container>
       </GeneralPublicLayout> 
    )
}