import { Button, Container, Link, TextField, Typography } from "@mui/material";
import { useRegister } from "@refinedev/core";
import { link_buttons } from "components/header/menu";
import { useNavigate } from "react-router-dom";
import { SiteIcon, site_name, site_domain } from "util.config";

export const Register = () => {
  let navigate = useNavigate()
  const { mutate: register } = useRegister<any>();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const values = {
      first: e.currentTarget.first.value,
      last: e.currentTarget.last.value,
      username: e.currentTarget.first.value+e.currentTarget.last.value,
      dob: e.currentTarget.dob.value,
      email: e.currentTarget.email.value,
      password: e.currentTarget.password.value,
      phone: e.currentTarget.phone.value,
      address: e.currentTarget.address.value,
      city: e.currentTarget.city.value,
      state: e.currentTarget.state.value,
      zip_code: e.currentTarget.zip_code.value,
    };

    register(values);
  };

  let input_break = {height:25}

  return (
    <Container>
      <Typography fontFamily={'"Pacifico", "Impact"'} variant="h4" style={{cursor:'pointer', justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}} onClick={() => navigate('/')}>
        <SiteIcon />{site_name}
      </Typography>
      <Typography variant="h4" style={{marginBottom:20}}>
      New Marijuana Customer Information
      </Typography>
      <form onSubmit={onSubmit}>

        <TextField name='first' required id="outlined-basic" label="First Name" variant="outlined" />
        {" "}
        <TextField name='last' required id="outlined-basic" label="Last Name" variant="outlined" />

        <div style={input_break} />
        <Typography>
          Date Of Birth
        </Typography>
        <TextField name='dob' required type="date" id="outlined-basic" variant="outlined" />

        <div style={input_break} />
        <TextField name='email' required type="email" id="outlined-basic" label="Email" variant="outlined" />

        <div style={input_break} />
        <TextField name='password' required type="password" id="outlined-basic" label="Password" variant="outlined" />

        <div style={input_break} />
        <TextField name='phone' required id="outlined-basic" label="Phone" variant="outlined" />

        <div style={input_break} />
        <TextField name='address' required id="outlined-basic" label="Street Address" variant="outlined" />
        {" "}
        <TextField required name='city' id="outlined-basic" label="City" variant="outlined" />
        {" "}
        <TextField required name='state' id="outlined-basic" label="State" variant="outlined" />
        {" "}
        <TextField required name='zip_code' id="outlined-basic" label="Zip Code" variant="outlined" />

        <Typography variant="h5" style={{marginTop:20}}>
        We are required to verify your age and identity in order to deliver legal marijuana to you. Please submit of one of the following documents:
        </Typography>
        <Typography>
        <ul>
          <li>Valid driver's license</li>
          <li>Valid government-issued ID</li>
        </ul>
        </Typography>
        <Typography>
          Send a copy of your driver's license to verification@{site_domain}.
          <br />
          You will be updated via email upon successful verification.
        </Typography>
        
        <div style={input_break} />
        <Button variant="contained" type="submit">Submit</Button>
    </form>
    <Container style={{padding:20}}>
      <Typography style={{textAlign:'center'}}>
        Already have an account? {" "} 
        <Link style={link_buttons} onClick={() => navigate("/login")} variant="body1">
          Log in.
        </Link>
      </Typography>
    </Container>
    </Container>
  )
};
