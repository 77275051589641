import { Container, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { site_name, SiteIcon } from "util.config"

export const Footer = () => {
    let navigate = useNavigate()
    return (
        <>
        <Container style={{padding:20, backgroundColor:'lightgray', marginTop:20}}>
            <small>
            Your locally owned dispensary since 2014. We deliver legal, lab-tested marijuana products to all locations in Orange County, California – including Newport Beach, Laguna Beach, Anaheim, Huntington Beach, Costa Mesa, Mission Viejo, and Santa Ana. Our legendary 420 delivery team is fast and discreet. We deliver award-winning recreational weed, edibles, and vape carts directly to your door. Please have exact amount cash ready to complete your marijuana order. Our drivers carry no cash.
            </small>
        </Container>
        <Grid style={{marginTop:20, marginBottom:20, borderTopStyle:'solid'}} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        
            <Grid item xs={4} sm={8} md={4}>
                <Typography fontFamily={'"Pacifico", "Impact"'} variant="h4" style={{cursor:'pointer', justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}} onClick={() => navigate('/')}>
                    <SiteIcon />{site_name}
                </Typography>
                <small style={{paddingLeft:10, justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}}>
                ©2023 {site_name} Orange County Weed Delivery | Irvine, California 92618
                </small>
            </Grid>
        
            <Grid item xs={2} sm={4} md={4} textAlign={'center'}>
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://bacpacio.tawk.help/" target="_blank">Help Center</a>
                   <br />
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://tawk.to/chat/65b4d0c90ff6374032c57b11/1hl54v4k7" target="_blank">Contact Us</a>
                   <br />
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://bacpacio.tawk.help/" target="_blank">Submit Ticket</a>
            </Grid>
        
            <Grid item xs={2} sm={4} md={4} textAlign={'center'}>
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)', cursor:'pointer'}} onClick={() => navigate("/public/return-policy")} target="_blank">Return Policy</a>
                   <br />
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)', cursor:'pointer'}} onClick={() => navigate("/public/privacy-policy")} target="_blank">Privacy Policy</a>
            </Grid>
        </Grid>
        </>
    )
}