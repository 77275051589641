import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import ProductMenu from './product_menu';
import Link from '@mui/material/Link';
import { link_buttons } from './menu';

export const MobileMenu = () => {
  let navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{textAlign:'center', marginTop:10}}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
      >
        Main Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate("/public/bud-delivery")}><Link style={link_buttons}>Home</Link></MenuItem>
        <MenuItem><ProductMenu /></MenuItem>
        <MenuItem onClick={() => navigate("/public/delivery-areas")}><Link style={link_buttons}>Delivery Areas</Link></MenuItem>
        <MenuItem onClick={() => navigate("/public/faq")}><Link style={link_buttons}>FAQ</Link></MenuItem>
        <MenuItem onClick={() => navigate("/public/new-customers")}><Link style={link_buttons}>New Customers</Link></MenuItem>
        <MenuItem onClick={() => navigate("/public/about-us")}><Link style={link_buttons}>About Us</Link></MenuItem>
      </Menu>
    </div>
  );
}