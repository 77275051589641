import { Typography } from "@mui/material"
import { GeneralPublicLayout } from "components/home/general.layout"

export const ReturnPolicy = () => {
    return(
       <GeneralPublicLayout>
            <Typography variant="h4">
                Return Policy
            </Typography>
            <Typography>
                All sales are final.
            </Typography>
            <Typography variant="h4" style={{paddingTop:20}}>
            My cannabis vape cartridge isn’t working, what do I do?
            </Typography>
            <Typography>
            Check these items first:
            <br />
            <ol>
                <li>Make sure your battery is fully charged.</li> 
                <li>Make sure your battery has enough voltage to heat up your vape cartridge.</li>
                <li>Check to see if your cartridge is twisted on too tight or too loose.</li> 
                <li>Examine your cartridge and remove anything blocking the mouthpiece.</li>
            </ol>
            If you followed all of these steps and the weed cartridge you purchased from us is still not working, give us a call! 
            </Typography>
       </GeneralPublicLayout> 
    )
}