import { Button, Typography } from "@mui/material"
import { GeneralPublicLayout } from "components/home/general.layout"
import { useNavigate } from "react-router-dom"
import { site_name, site_phone_number } from "util.config"

export const AboutUs = () => {
    let navigate = useNavigate()
    return(
       <GeneralPublicLayout>
            <Typography variant="h4" style={{marginBottom:20}}>
            {site_name} Orange County Weed Delivery: Flower, Vapes, Edibles
            </Typography>
            <Typography>
            <img style={{float:'right', width:"100%", maxWidth:500, padding:20}} src="https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/canna_delivery.jpg" />
            {site_name} OC is a locally owned marijuana dispensary, serving Orange County residents since 2014. We deliver premium marijuana delivery to weed lovers across Orange County, California – including Irvine, Santa Ana, Anaheim, Newport Beach, Huntington Beach, Laguna Beach, Garden Grove, Mission Viejo, and other nearby cities. {site_name} is the top rated weed delivery dispensary in Orange County. We deliver only top-shelf, third-party, lab-tested cannabis products that are produced with minimum carbon footprint. We work with veteran organic cannabis farmers, who are masters of weed cultivation. Our product selection is curated to meet the marijuana preferences of Orange County residents – backed by world-class 420 concierge service and discretion.
            </Typography>
            <Button onClick={() => navigate("/public/products")} variant="contained" style={{marginTop:20, marginBottom:20}}>Shop Weed Now</Button>
            <Typography>
            We deliver only the finest marijuana flower from veteran weed producers in California. Our mission is to provide marijuana products to connoisseurs who want only the very finest quality in cannabis – whether marijuana flower, edibles, concentrates, or vape carts. If you need top-shelf weed delivery in Irvine, Santa Ana, Anaheim, Laguna Beach, Huntington Beach, Newport Beach, or elsewhere in Orange County, we are ready to enhance your SoCal state of mind with the finest and most popular marijuana products – delivered promptly to your home, hotel, or workplace.
            <br /><br />
            Our marijuana dispensary menu includes a wide variety cannabis sativa, indica, and hybrid flower as well as marijuana edibles and concentrates. The {site_name} weed delivery team is professional, discreet, and knowledgeable. Be sure to ask if you have questions about any of our cannabis products, which include marijuana flower, edibles, pre-rolls, vapes, concentrates, grinders, pipes, and other forms of THC and CBD consumption.
            </Typography>
            <Typography variant="h4" style={{marginTop:20}}>
            {site_name} Cannabis Quality and Purity
            </Typography>
            <ul>
                <li>{site_name} uses third-party marijuana testing companies to ensure purity of all cannabis products.</li>
                <li>We work closely with marijuana producers to ensure consistently high quality products – including cannabis flower, edibles, concentrates, and cartridges.</li>
            </ul>
            
            <Typography variant="h4" style={{marginTop:20}}>
            You’re in Good Hands with {site_name} Weed Delivery
            </Typography>
            <ul>
                <li>{site_name} OC features organic, gluten-free, vegan marijuana products.</li>
                <li>We carry top-shelf quality cannabis products only – no mid to lower shelf strains</li>
                <li>We deliver the highest potency indoor and outdoor marijuana flower available</li>
                <li>We work with veteran California cannabis farmers to ensure all marijuana plants are grown responsibly, free of pesticides, and with minimum carbon footprint</li>
            </ul>
            <Typography>
            To try {site_name} OC premium marijuana dispensary, view our menu or call now and speak with our weed delivery service team at {site_phone_number}. We’ll have you registered immediately will dispatch a weed delivery driver to your location right away. Visit our convenient online shop to view the full weed delivery service menu.
            <br /><br />
            And remember… we sell every 1/8th in 4 gram packages because we think 3.5 grams is not enough.
            <br /><br />
            Thanks to all OC weed lovers! We hope you enjoy your experience in Newport Beach, Laguna Beach, Huntington Beach, Irvine, Santa Ana, Anaheim, and across Orange County!
            <br /><br />
            Your {site_name} OC Weed Delivery Team
            </Typography>
            
       </GeneralPublicLayout> 
    )
}