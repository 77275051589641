import { Container } from "@mui/material"
import { Footer } from "components/footer"
import { PublicHeader } from "components/header/public_header"

export const GeneralPublicLayout = ({children}:any) => {
    window.scrollTo(0, 0)
    return (
        <>
        <PublicHeader />
            <Container style={{paddingTop:20}}>
                {children}
            </Container>
        <Footer />
        </>
    )
}