import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import { useMany } from '@refinedev/core';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { link_buttons } from './menu';
import { BreakfastDining, Hvac, LocalFlorist, LocalOffer, Medication, ShoppingBag, SmokingRooms, VapingRoomsTwoTone, Yard } from '@mui/icons-material';

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  
  const [listed_categories, setListedCategories] = React.useState<any>([]);
  const [singleCategory, setSingleCat] = React.useState<any>([]);

  let navigate = useNavigate()

  const { data, isLoading, isError } = useMany<any>({
      resource: "category/find",
      ids:[],
  });

  function getOccurrence(array:any, value:any) {
      var count = 0;
      array.forEach((v:any) => (v === value && count++));
      return count;
  }
  
  const categories = data?.data ?? [];

  React.useEffect(() => {
      const allCategories:any = []
      categories.filter(item => item.categories.filter((x:any) => allCategories.push(x)))
      setListedCategories(allCategories)
      setSingleCat(allCategories.reduce(function(a:any,b:any){if(a.indexOf(b)<0)a.push(b);return a;},[]))
  }, [categories])

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Menu</DialogTitle>
      <List sx={{ pt: 0 }}>
        {singleCategory.map((item:any, index:any) => (
          <ListItem disableGutters key={index}>
            <ListItemButton onClick={() => {
                navigate('/public/categories/show/'+item)
                handleClose()
                }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  {index < 5 && <LocalFlorist />}
                  {index === 5 && <BreakfastDining />}
                  {index === 6 && <Medication />}
                  {index === 7 && <Medication />}
                  {index === 8 && <LocalOffer />}
                  {index === 9 && <VapingRoomsTwoTone />}
                  {index === 10 && <ShoppingBag />}
                  {index === 11 && <SmokingRooms />}
                  {index === 12 && <Yard />}
                  {index === 13 && <Hvac />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item+" ("+getOccurrence(listed_categories, item)+") "} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default function ProductMenu() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  return (
    <>
      <Link onClick={handleClickOpen} style={link_buttons}>
        Cannabis Menu
      </Link>
      <SimpleDialog
        selectedValue={""}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}