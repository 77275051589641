import { useContext, useEffect, useState } from "react";
import { useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Card, CardContent, Chip, Container, Grid, Typography } from "@mui/material";
import { CartContext, CartDispatchContext } from "contexts/cart";
import { Add, CurrencyExchange, ExpandMore } from "@mui/icons-material";
import { PublicHeader } from "components/header/public_header";
import { Footer } from "components/footer";
import { PurchaseOptions } from "components/products/purchase_options";
import ProductOptions from "components/products/options";
import { site_name } from "util.config";

export const ProductShow: React.FC = () => {

  let { id } = useParams()

  const { data, isLoading, isError } = useOne<any>({
    resource: "product",
    id,
  });

  const product = data?.data;

  const [main_image, setMainImage] = useState('')

  useEffect(() => {
    setMainImage(product?.images[0])
  }, [product])

  const cartDetails = useContext(CartContext);
  const setCartDetails = useContext(CartDispatchContext);
  
  const handleCart = (item:object) => {
    setCartDetails([...cartDetails, item])
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <>
    <PublicHeader />
    <Container style={{padding:30}}>
      <Typography style={{padding:30}} fontFamily={'Impact'} variant="h4">{product.title}</Typography>
      <Grid style={{display:'flex', justifyContent:'space-around'}} container columns={{ xs: 4, md: 12 }}>
          <Grid item>
            <Box style={{maxWidth:500}}>
              <img style={{width:'100%'}} src={main_image} alt={product?.title} />
            </Box>
          </Grid>
          <Grid item>
              <Box style={{maxWidth:500}}>
                <Typography fontFamily={'Impact'} variant="h4">
                  {product?.type}
                </Typography>
                <Typography variant="h4">
                  ${product?.price}.00
                </Typography>
                <ProductOptions title={product?.title} />
                <Typography>
                  Sku : {product?.sku}
                </Typography>

                <Button fullWidth variant="contained" style={{marginTop:10, marginBottom:10}} startIcon={<Add />} onClick={() => handleCart(product)} size="small">Add to Cart</Button>

                <small>Categories :</small> {product?.category.map((item:any) => 
                  <small key={item}>{item+" | "}</small>
                )}
                <Typography>
                    {product?.description.replace("Description", "")}
                </Typography>
                {product?.specs.map((item:any) =>
                <>
                  <div style={{height:30}} /> 
                  <Typography>
                      {Object.keys(item)+" "+Object.values(item)}
                  </Typography>
                </>
                )}
                
                <div style={{marginTop:20, marginBottom:20, paddingTop:10, paddingBottom:10, borderTopStyle:'solid', borderBottomStyle:'solid'}}>

                  <Typography variant="h6" style={{marginBottom:5}}>
                    <b>Shop with confidence</b>
                  </Typography>
                  <Grid container>
                    <Grid item xs={2} style={{textAlign:'center'}}>
                      <CurrencyExchange style={{fontSize:"45px"}} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>
                        <b>{site_name} Money Back Guarantee</b>
                      </Typography>
                      
                      <Typography>
                        Get the item you ordered or your money back.
                      </Typography>
                    </Grid>

                  </Grid>

                </div>
              </Box>
          </Grid>
      </Grid>
      <Footer />
    </Container>
    </>
  );
};