import { Typography } from "@mui/material";
import { AuthPage } from "@refinedev/mui";
import { useNavigate } from "react-router-dom";
import { SiteIcon, site_name } from "util.config";

export const Login = () => {
  let navigate = useNavigate();
  return (
    <AuthPage
      type="login" 
      title={
      <Typography fontFamily={'"Pacifico", "Impact"'} variant="h4" style={{cursor:'pointer', justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}} onClick={() => navigate('/')}>
        <SiteIcon />{site_name}
      </Typography>
      } 
    />
  );
};
