import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMany } from '@refinedev/core';
import { Card, CardActionArea, CardContent, Chip, Grid } from '@mui/material';
import { Add, LocalOffer } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { CartContext, CartDispatchContext } from 'contexts/cart';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: '400px',
  overflow: 'auto',
};

export default function ProductOptions(props:any) {
  const { title } = props
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let navigate = useNavigate()

  const { data, isLoading, isError } = useMany<any>({
    resource: "product/find",
    ids:[],
    meta: {
      headers:{
        title:encodeURI(title)
      }
    },
  });

  const products = data?.data ?? [];
  const cartDetails = React.useContext(CartContext);
  const setCartDetails = React.useContext(CartDispatchContext);
  
  const handleCart = (item:object) => {
    setCartDetails([...cartDetails, item])
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <div>

      <Chip style={{margin:10}} onClick={handleOpen} label="More Options" variant="outlined" />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography style={{padding:30}} fontFamily={'Impact'} variant="h4">{title}</Typography>

        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 2, sm: 4, md: 8 }}>
        {products.map((product, index) => (
        <Grid item xs={1} sm={4} md={4} key={index}>
             <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => {
                navigate("/public/products/show/"+product._id)
                handleClose()
                }
              }>
                <img
                    style={{ width: '100%', backgroundColor:'white', padding:10 }}
                    src={product.images[0]}
                    alt={product.title}
                />
                <CardContent>
                    <Typography style={{display:'flex', alignItems:'center', backgroundColor:'rgba(103, 183, 247, 0.16)', padding:5}} variant="h5">
                        <LocalOffer style={{fontSize:15, marginRight:5}} />${product.price}.00
                    </Typography>
                    <Typography style={{fontWeight:900}}>
                        {product.type}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        SKU: {product.sku}
                    </Typography>
                </CardContent>
              </CardActionArea>
              <Button style={{margin:10}} startIcon={<Add />} onClick={() => handleCart(product)} size="small">Add to Cart</Button>
            </Card>
        </Grid>
        
        ))}
    </Grid>
        </Box>
      </Modal>
    </div>
  );
}