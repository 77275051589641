import { AccountCircle, ShoppingCart } from "@mui/icons-material"
import { Badge, Button, Container, Dialog, DialogActions, DialogContent, Slide, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import { useMany } from "@refinedev/core";
import { CartItems } from "components/cart/cart_item";
import { CartContext } from "contexts/cart";
import { useContext, useState, forwardRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions, { site_email, site_name, site_phone_number, SiteIcon } from "util.config";
import MainMenu from "./menu";
import { MobileMenu } from "./mobile_menu";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const PublicHeader = () => {
    const { data, isLoading, isError } = useMany<any>({
      resource: "product/find",
      ids:[]
    });

    const products = data?.data ?? [];

    const getProductLabels = () => {
      let labels:any = []
      products.filter((item:any) => labels.push({label:item.type+" "+item.title}))
      return labels
    }
  
    const [backdrop, setBackDrop] = useState(false)
    const handleBackdrop = () => setBackDrop(!backdrop)

    const navigate = useNavigate()
  
    const cartDetails = useContext(CartContext);

    // see if client is logged in or publicly viewing
    const location = useLocation();
    let isPublic = location.pathname.includes('public')
  
    return (
      <>
      <Container style={{background:'black', color:'white', padding:20, maxWidth:window.innerWidth}}>
          {site_name} Orange County
          <br />
          Fast Weed Delivery
        </Container>
      <Container style={{/*position:'sticky', top:0, zIndex:1,*/ backgroundColor:'rgba(108, 247, 103, 0.16)', padding:20, maxWidth:window.innerWidth}}>
        
      <div style={{textAlign:'left'}}>
        Pay with cryptocurrency 
        <br />
        or cash on delivery
      </div>
      <div style={{textAlign:'right', marginTop:-20, marginBottom:60}}>
        <br />
        {site_phone_number}
        <br />
        {site_email}
        <br />
        {isPublic &&
        <Button onClick={() => navigate('/login')} style={{float:'right'}} variant="contained" startIcon={<AccountCircle />}>
            My Account
        </Button>
        }
      </div>

        <Container>
          <Typography fontFamily={'"Pacifico", "Impact"'} variant="h4" style={{cursor:'pointer', justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}} onClick={() => navigate('/')}>
            <SiteIcon />{site_name}
          </Typography>
          <div style={{textAlign:'center', marginTop:-20}}>
              Open every day
              <br />
              10 A.M. - 10 P.M.
          </div>
          
          {isPublic &&
            <Container style={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center'}}>
              {cartDetails.length > 0 &&
              <Slide direction="up" in={cartDetails.length > 0} mountOnEnter unmountOnExit>
              <div style={{position:'fixed', right:10, top:0, backgroundColor:'#1976D2', padding:20, color:'white', zIndex:1}}>
                <Badge onClick={handleBackdrop} badgeContent={cartDetails.length} color="secondary">
                  <ShoppingCart style={{fontSize:40}} />
                </Badge>
              </div>
              </Slide>
              }
              
            </Container>
          }
        
        </Container>
        <Dialog
            open={backdrop}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleBackdrop}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <CartItems />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleBackdrop}>Continue Shopping</Button>
                
                {cartDetails.length > 0 &&
                  <Button onClick={() => {
                  navigate('/public/cart')
                  handleBackdrop()
                }}>Checkout</Button>}
            </DialogActions>
        </Dialog>
      {useWindowDimensions().width < 650 ? <MobileMenu /> : <MainMenu />} 
    </Container>
    </>
    )
}