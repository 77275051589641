import { Button, Typography } from "@mui/material"
import { GeneralPublicLayout } from "components/home/general.layout"
import { Register } from "pages/register"
import { useNavigate } from "react-router-dom"
import { site_email, site_phone_number } from "util.config"

export const NewCustomers = () => {
    let navigate = useNavigate()
    return(
       <GeneralPublicLayout>
            <Typography variant="h4" style={{marginBottom:20}}>
                Sign Up for Marijuana Delivery Now
            </Typography>
            <Typography variant="h4">
                Your Locally Owned Dispensary in Orange County, California
            </Typography>
            <Typography>
                Whether you’re a long-time resident of Orange County or just passing through for work or vacation, we look forward to making your visit even better with the finest weed flower, cannabis vape carts, and marijuana edibles.
                <br /><br />

                <img style={{float:'left', maxWidth:250, padding:20}} src="https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/budtender.jpg" />

                We have been the premier marijuana delivery company in Orange County, California since 2014. We are locally owned and operated and serve exclusively Orange County, California. We provide fast, discreet weed delivery to private residences, hotels, AirBnB units, workplaces, businesses, and beaches.
                <br /><br />

                All marijuana flower is lab-tested for purity, ensuring all marijuana products are free of chemicals and pesticides. We work directly with veteran marijuana farmers and companies to ensure we deliver only the highest cannabis products.
                <br /><br />

                <img style={{float:'right', maxWidth:300, padding:20}} src="https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/newcustomergift.png" />

                Please upload your 21+ government-issued ID below. Once you are verified, you can order directly from our weed delivery menu online or give us a call us to place your marijuana order. Our delivery drivers are discreet and respect the privacy of cannabis customers. Drivers do not carry change so please have exact cash ready to complete your transaction.
                <br /><br />
                For more information, please call {site_phone_number} or email us at {site_email}. We’re happy to answer your questions about our cannabis products and the weed delivery process.
            </Typography>
            <Button onClick={() => navigate("/public/products")} variant="contained" style={{marginTop:20, marginBottom:20}}>Shop Marijuana Products Now</Button>
            <Register />
       </GeneralPublicLayout> 
    )
}