import { useContext, useState } from "react";
import { useMany } from "@refinedev/core";
import { Button, Card, CardActionArea, CardContent, Container, Grid, Typography } from "@mui/material";
import { Add, ArrowBack, ArrowForward, LocalOffer } from "@mui/icons-material";
import { CartContext, CartDispatchContext } from "contexts/cart";
import { PublicHeader } from "components/header/public_header";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "components/footer";
import ProductOptions from "components/products/options";
import { ProductFilter } from "components/header/filter";

export const CategoryShow: React.FC = () => {
  let navigate = useNavigate()

  const { id } = useParams()
  const [price, setPrice] = useState(1)
  const [skipAmount, setSkipAmount] = useState(0)

  const { data, isLoading, isError } = useMany<any>({
    resource: "product/find",
    ids:[],
    meta: {
      headers:{
        category:id,
        price,
        skip:skipAmount
      }
    }
  });

  const products = data?.data ?? [];

  const cartDetails = useContext(CartContext);
  const setCartDetails = useContext(CartDispatchContext);
  
  const handleCart = (item:object) => {
    setCartDetails([...cartDetails, item])
  }

  window.scrollTo(0, 0)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <>
    <PublicHeader />
    <Container>
    <div style={{display:'flex', justifyContent:'center', margin:20}}>
      <Button onClick={() => navigate('/public/categories')} variant="contained">View All Categories</Button>
    </div>
    <Typography variant="h5" textAlign={'center'} fontFamily={'Impact'} style={{marginBottom:20}}>
      Category : {id}
    </Typography>
    <ProductFilter price={price} setPrice={setPrice} />
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {products.map((product, index) => (
        <Grid item xs={2} sm={4} md={4} key={index}>
             <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => navigate("/public/products/show/"+product._id)}>
                <img
                    style={{ width: '100%', backgroundColor:'white', padding:10 }}
                    src={product.images[0]}
                    alt={product.title}
                />
                <CardContent>
                    <Typography style={{display:'flex', alignItems:'center', backgroundColor:'rgba(103, 183, 247, 0.16)', padding:5}} variant="h5">
                        <LocalOffer style={{marginRight:5}} />${product.price}.00
                    </Typography>
                    <Typography>
                        {product.type+" - "+product.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        SKU: {product.sku}
                    </Typography>
                </CardContent>
              </CardActionArea>
                <ProductOptions title={product.title} />

                <Button style={{margin:10}} startIcon={<Add />} onClick={() => handleCart(product)} size="small">Add to Cart</Button>

            </Card>
        </Grid>
        ))}
    </Grid>
    <Container style={{textAlign:'center', padding:20}}>
      {skipAmount > 0 && <Button startIcon={<ArrowBack />} variant="contained" onClick={() => setSkipAmount(skipAmount-1)}>Previous Page</Button>}
      {" "}
      {products.length > 20 && <Button endIcon={<ArrowForward />} variant="contained" onClick={() => setSkipAmount(skipAmount+1)}>Next Page</Button>}
    </Container>
    <Footer />
    </Container>
    </>
  );
};
