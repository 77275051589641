import { Button, Typography } from "@mui/material"
import { GeneralPublicLayout } from "components/home/general.layout"
import { WeedDeliveryLocationsList } from "."
import { site_name } from "util.config"
import { useNavigate } from "react-router-dom"

export const DeliveryAreas = () => {
    let navigate = useNavigate()
    let order_steps = ["First-time customers, please click here to upload a photo of your ID (Sorry, this is required by California law.)", "Visit our online menu and order up to one ounce of marijuana flower or eight grams of concentrates.", "After completing your online order, you will receive confirmation by email. You may reply to this email if you have any questions or want to change your order.", "Our team springs into action to pack your order and hand off your goodies to the driver within minutes.", "Our professional delivery drivers locate your address and identify the most efficient route to you.", "Our driver arrives at your location, typically within an hour or less.", "Please have exact cash ready. Our drivers carry do not carry change.", "Enjoy a premium cannabis session, and scan the QR on your receipt for extra goodies!"]
    return(
       <GeneralPublicLayout>
            <Typography variant="h4">
            Fast & Discreet Weed Delivery for Orange County, California
            </Typography>
            <Button onClick={() => navigate("/public/products")} style={{marginTop:30, marginBottom:30}} variant="contained">
                Shop Cannabis Now
            </Button>
            <Typography variant="h4">
            Your locally owned marijuana dispensary since 2014
            </Typography>
            <Typography>
            "Is there weed delivery near me?" If you live in Orange County, California or you're just visiting, we have good news for you: Hell yeah! We deliver marijuana orders 10am to 10pm every day of the year to homes, hotels, and businesses throughout Orange County and adjacent SoCal cities.
            <br /><br />
            All cannabis products are lab tested and pesticide free. We partner with veteran cannabis cultivators from across California to ensure eco-friendly farming practices as well as the most delicious, potent marijuana on the planet.
            </Typography>
            <Typography variant="h5" style={{marginTop:30}}>
            How to order weed delivery in Orange County, California:
            </Typography>
            <ol>
                {order_steps.map((step, idx) =>
                <li key={idx}>{step}</li>
                )}
            </ol>
            <WeedDeliveryLocationsList title={'Weed Delivery to Your Home, Workplace, or Hotel in OC'} />
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d850388.1460651378!2d-119.02905867416501!3d33.635683441248275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc925c54d5f7cf%3A0xdea6c3618ff0d607!2sOrange%20County%2C%20CA!5e0!3m2!1sen!2sus!4v1711906951083!5m2!1sen!2sus" width={"100%"} height={"450"} style={{border:0}} allowFullScreen={true} loading="lazy" referrerPolicy={"no-referrer-when-downgrade"}></iframe>
            <Typography variant="h4" style={{marginTop:30, marginBottom:30}}>
            {site_name} OC Weed Delivery Is Your Mobile Dispensary
            </Typography>
            <Typography variant="h4">
            Why is weed delivery so prevalent in Orange County, California?
            </Typography>
            <Typography>
            Marijuana weed delivery service plays a unique role in Orange County, California. Cities and municipalities in California have the option to prohibit storefront marijuana dispensaries within their respective jurisdictions. As a result, many California weed lovers have no direct access to a retail cannabis shop. Nearly two-thirds of California cities do not permit retail cannabis dispensaries where customers can browse and inspect the stinky, sticky merchandise in person.
            <br /><br />
            But just like during the pre-legalization days of yore: marijuana aficionados always find a way to access their beloved buds. In this case, legal marijuana delivery service has filled the vacuum left by restrictions on cannabis dispensary stores. All across California, including in Orange County, 420 enthusiasts can get marijuana products delivered to virtually any home, office, or hotel location – even if dispensaries are prohibited in their town. And thanks to rulings by the Bureau of Cannabis Control and California Supreme Court, weed delivery services now enjoy full protection under California law. Local jurisdictions may not place restrictions or prohibitions on marijuana delivery to privates residences, workplaces, or hotels/Airbnb rentals.
            </Typography>
            <Button onClick={() => navigate("/public/products")} variant="contained">Order Weed Delivery Now</Button>
       </GeneralPublicLayout> 
    )
}