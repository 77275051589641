import { Container } from "@mui/material"

export const PurchaseOptions = () => {
    return (   
        <Container style={{textAlign:'center'}}>
            <b>
            Our checkout is powered by:
            </b>
            <br />
            <img src="https://storage.googleapis.com/www.upperiot.com/bacpac/coinbase_logo.png" width={80} />
            <br />
            <img src="https://storage.googleapis.com/www.upperiot.com/bacpac/coinbase_banner.jpg" width={250} />
        </Container>
    )
}