import { Box, Button, Container, Dialog, DialogContent, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useCreate, useGetIdentity } from "@refinedev/core";
import { CartContext } from "contexts/cart";
import axios from "axios";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const CreateOrder = (props:any) => {
    const [value, setValue] = useState(0);
    const [googlePlaces, setGooglePlaces] = useState<any>('')
    const [subtotal, setSubtotal] = useState(0)
    const [paylink, setPaylink] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [zip_code, setZipCode] = useState('')

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    
    const { mutate } = useCreate();

    const { data: user } = useGetIdentity<any>();
    const cartDetails = useContext(CartContext);
    const [transaction_id, setTransId] = useState('')
    const [openPaylinkBx, setOpenPaylinkBox] = useState(false)

    useEffect(() => {
      let local_subtotal:any = []
      cartDetails.filter((item:any) => local_subtotal.push(Number(item.price)))
      setSubtotal(local_subtotal.reduce((a:any, b:any) => a + b, 0))
    }, [cartDetails])

    let final_total = (Number(subtotal)).toLocaleString('en-US', {style: 'currency', currency: 'USD', })

    const handleSubmitOrder = (cash:boolean) => {
      props.setConfirm(true)

      // save cart

      // remove localStorage.getItem('cart')

      if(cash){
        mutate({
          resource: "order",
          values: {
            userID: user?.id,
            cart: cartDetails,
            total: Number(subtotal),
            address : googlePlaces,
            zip_code,
            paylink: "paying on delivery",
            phone_number,
            email: user?.email,
            transactionID:user?.id+(new Date()).getTime()
          },
          meta: {
            headers: {
              token: user?.token
            }
          }
        });

      } else {

        // create coinbase paylink
        let data = {
          "name":"BacPac.io Purchase",
          "pricing_type":"fixed_price",
          "local_price": {
            "amount": Number(subtotal),
            "currency": "USD"
          }
        }
        axios.post("https://api.commerce.coinbase.com/charges", data, {
          headers: {
            'X-CC-Api-Key': 'fa23a6d6-29d6-4b3d-9268-69baa5ec958b'
          }
        })
        .then(results => {
          let local_paylink = results.data.data.hosted_url
          let transactionID = results.data.data.id
          setTransId(transactionID)
          setPaylink(local_paylink)
          setOpenPaylinkBox(true)
          // create order with paylink and transactionID
          mutate({
            resource: "order",
            values: {
              userID: user?.id,
              cart: cartDetails,
              total: Number(subtotal),
              address : googlePlaces,
              zip_code,
              paylink: local_paylink,
              phone_number,
              email: user?.email,
              transactionID
            },
            meta: {
              headers: {
                token: user?.token
              }
            }
          });
        })
        .catch(err => alert(err+" Please try again later."))
      }
    }
    
    return (
        <>  
            {props.confirm &&
            <div>
                Order ID : {transaction_id}
            </div>
            }
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Delivery" {...a11yProps(0)} />
            </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Container>
                    <Typography style={{margin:20}}>
                        Enter your delivery details:
                    </Typography>
                    <TextField fullWidth  onChange={(e) => setPhoneNumber(e.target.value)} id="outlined-basic" label="Contact Number" variant="outlined" />
                    <br /><br />
                    <TextField fullWidth multiline onChange={(e) => setGooglePlaces(e.target.value)} id="outlined-basic" label="Full Address" variant="outlined" />
                    <br /><br />
                    <TextField fullWidth value={'CA'} id="outlined-basic"  variant="outlined" />
                    <br /><br />
                    <TextField fullWidth  onChange={(e) => setZipCode(e.target.value)} id="outlined-basic" label="Zip Code" variant="outlined" />
                </Container>
            </CustomTabPanel>
            {googlePlaces && phone_number && zip_code ?
            <>
              <div>
                Subtotal : {subtotal.toLocaleString('en-US', {style: 'currency', currency: 'USD', })}
              </div>
              <div>
                USD Total Due : {final_total}
              </div>
              {!props.confirm &&
              <Button onClick={() => handleSubmitOrder(true)} style={{marginTop:20}} fullWidth variant="contained" color="warning">
                  cash on delivery
              </Button>
              }
              {!props.confirm &&
              <Button onClick={() => handleSubmitOrder(false)} style={{marginTop:20}} fullWidth variant="contained" color="warning">
                  Pay using Coinbase
              </Button>
              }
              <Dialog
                open={openPaylinkBx}
                onClose={() => setOpenPaylinkBox(!openPaylinkBx)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <a href={paylink}>
                    <Button variant="contained">
                      Choose Payment Method
                    </Button>
                  </a>
                </DialogContent>
              </Dialog>
            </>
            :""
            }
        </>
    )
}