import { IResourceComponentsProps, useOne, useGetIdentity } from "@refinedev/core";
import {
    Show,
    DeleteButton,
} from "@refinedev/mui";
import { List, ListSubheader, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Cancel, CheckCircle} from "@mui/icons-material";
import { useState } from "react";
import { site_domain } from "util.config";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity<any>();
    const [id, setId] = useState('');

    const { data, isLoading, isError } = useOne<any>({
        resource: "user/find/"+user?.id,
        id,
        meta: {
            headers:{
                token: user?.token
            }
        }
    });

    const record = data?.data.others;
    

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    let listStyle = { width: window.innerWidth, maxWidth:700, margin:'auto', bgcolor: 'background.paper' }

    return (
        <Show title={false} breadcrumb={false} isLoading={isLoading} headerButtons={({ defaultButtons }) => (
                <>
                    <DeleteButton />
                </>
            )}>
            
            <List
                sx={listStyle}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Personal Details
                    </ListSubheader>
                }
            >
                {[
                    {primary:"ID", secondary:record?._id, icon:<CheckCircle />},
                    {primary:"Status", secondary:record?.verified ? "Verified" : "Not Verified, Please send your ID to verification@"+JSON.stringify(site_domain).replaceAll('"', ''), icon:!record?.verified ? <Cancel color="error" /> : <CheckCircle />},
                    {primary:"Email", secondary:record?.email, icon:<CheckCircle />},
                    {primary:"Phone Number", secondary:record?.phone, icon:<CheckCircle />},
                    {primary:"Address", secondary:record?.address, icon:<CheckCircle />},
                ].map((item:any, index:any) =>
                    <ListItemButton key={index}>
                        <ListItemIcon>
                        {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.primary} />
                        <ListItemText secondary={item.secondary} />
                    </ListItemButton>
                )}
                
            </List>

            
            <List
                sx={listStyle}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Developer Details
                    </ListSubheader>
                }
            >
                {[
                    {primary:"Auth ID", secondary:record?._id, icon:<CheckCircle />},
                ].map((item:any, index:any) =>
                    <ListItemButton key={index}>
                        <ListItemIcon>
                        {item.icon}
                        </ListItemIcon>
                        <ListItemText style={{wordWrap:'break-word'}} primary={item.primary} />
                        <ListItemText style={{wordWrap:'break-word'}} secondary={item.secondary} />
                    </ListItemButton>
                )}
                
            </List>
        </Show>
    );
};