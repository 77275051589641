/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import ProductMenu from './product_menu';

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export const link_buttons = {textDecoration:'none', cursor:'pointer'}

export default function MainMenu() {
    let navigate = useNavigate()
  return (
    <Box
      sx={{
        typography: 'body1',
        '& > :not(style) ~ :not(style)': {
          ml: 5,
        },
      }}
      onClick={preventDefault}
      style={{textAlign:'center', textDecoration:'none', marginTop:40}}
    >
      <Link style={link_buttons} onClick={() => navigate("/public/bud-delivery")} variant="body1">
        Home
      </Link>
      <ProductMenu />
      <Link style={link_buttons} onClick={() => navigate("/public/delivery-areas")} href="#" variant="body1">
        Delivery Areas
      </Link>
      <Link style={link_buttons} onClick={() => navigate("/public/faq")} href="#" variant="body1">
        FAQ
      </Link>
      <Link style={link_buttons} onClick={() => navigate("/public/new-customers")} href="#" variant="body1">
        New Customers
      </Link>
      <Link style={link_buttons} onClick={() => navigate("/public/about-us")} href="#" variant="body1">
        About Us
      </Link>
    </Box>
  );
}